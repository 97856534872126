.navbar {
  color: var(--white-color);
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--secondary-black-color);
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}

.logo-heading-holder {
  display: flex;
  align-items: center;
}

.logo-heading-holder > div {
  margin-left: 20px;
}

.logo-heading-holder p {
  font-size: 1.125rem;
  color: var(--white-color);
}

.logo {
  object-fit: contain;
  height: 50px;
}

.userinfo-holder {
  display: flex;
  cursor: pointer;
}

.userinfo-icon {
  color: var(--white-color);
  height: 16px;
  width: 16px;
  margin: 8px 10px;
  cursor: pointer;
}

.name-role-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.name-text {
  font-size: 1rem;
  color: var(--white-color);
  text-transform: capitalize;
}

.role-text {
  font-size: 0.75rem;
  color: var(--white-color);
}

.up-down-arrow-icon {
  height: 24px;
  width: 24px;
  margin: 8px 10px;
}

.menu-dropdown {
  margin-top: 10px;
}
