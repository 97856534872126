.accordian-global-padding {
    padding: 20px;
}

.priority-holder {
    display: flex;
    align-items: center;
    float: left
}

.priority-switch {
    width: 8rem;
}

.infooutlined-alignment {
    color: #676ac0;
    height: 20px;
}

.Avator {
    width: 0.9rem;
    height: 0.9rem;
    cursor: pointer;
}

.Upload-heading {
    padding-bottom: 1rem;
}

.button-position {
    float: right;
}