.image-dropdown-section {
  background-color: rgb(251, 247, 247);
  height: 240px;
  /* width: 320px; */
  width: 100%;
}

.flex-col-space-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upload-btn-wrapper {
  text-align: end;
  margin-top: 50px;
}

.upload-btn {
  width: 96px;
}

.display-flex-space-btw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-section {
  display: flex;
  flex-direction: row;
  margin: 0 16px;
}

.close-icon-btn {
  width: 48px;
  height: 32px;
}

.close-icon-btn-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.inner-replace-button-wrapper {
  position: relative;
  float: right;
  bottom: 32px;
  left: 40px;
  background-color: white;
  border: none;
  width: 100px;
  height: 27.2px;
  align-items: center;
  cursor: pointer;
}

.inner-replace-button-wrapper:hover {
  background-color: white;
}

.cached-icon {
  margin-right: 6px;
}

.replace-text {
  margin-right: none;
}

.preview-image-section {
  height: 240px;
  /* width: 320px; */
  width: 100%;
  object-fit: cover;
}

.image-upload-input {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.upload-icon {
  height: 30px;
  width: 30px;
}

.browse-files-link {
  color: #17171c;
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
}

.input-display-none {
  display: none;
}

.media-upload-content {
  margin-right: 48px;
  max-height: 320px;
}

.right-side-icon {
  background-color: var(--quaternary-white-color);
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
}

.delete-icon {
  color: red;
}

.right-side-button {
  position: relative;
  float: right;
  bottom: 32px;
  left: 10px;
  margin: auto;
  /* background-color :white; */
  border: none;
  width: 100px;
  height: 24px;
  align-items: center;
}

.right-side-button:hover {
  border: 1px solid blank;
}

.replace-button,
.replace-text,
.cached-icon {
  cursor: pointer;
}
