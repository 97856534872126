:root {
  /* LAYOUT DIMENSIONS */
  --sidemenu-width: 270px;
  /* --header-height: 90px; */
  --header-height: 80px;

  /* HoABL COLOR PALETTE */
  --transparent-color: rgba(246, 246, 249, 0);

  --white-color: #ffffff;
  --secondary-white-color: #fbfbff;
  --tertiary-white-color: #f6f6fa;
  --quaternary-white-color: #f7f6ff;

  --black-color: #000000;
  --secondary-black-color: #111111;
  --tertiary-black-color: #2d2d2d;
  --quaternary-black-color: #17171c;
  --quinary-black-color: #141615;

  --primary-theme-color: #676ac0;
  --secondary-theme-color: #53569b;
  --tertiary-theme-color: #242e4c;
  --quaternary-theme-color: #5348d0;
  --quinary-theme-color: #4a39ff;

  --dark-blue-color: #1f1a54;

  --primary-red-color: #ff485a;

  --dark-gray-color: #5a5f62;
  --secondary-dark-gray-color: #707070;
  --tertiary-dark-gray-color: #979797;

  --light-gray-color: #f7f6f8;
  --medium-gray-color: #a9a9a9;
  --secondary-light-gray-color: #eeeeee;

  --primary-blue-magenta-color: #8682bb;
  --secondary-blue-magenta-color: #c0bcef;
  --tertiary-blue-magenta-color: #c9c4ff;
  --quaternary-blue-magenta-color: #bab3ff;
  --quinary-blue-magenta-color: #686680;
  --senary-blue-magenta-color: #9795af;

  --primary-gray-color: #f7f7f7;
  --secondary-gray-color: #f8f8f8;
  --tertiary-gray-color: #eaeaf1;
  --quaternary-gray-color: #afaeb7;
  --quinary-gray-color: #979797;

  --success-green: #1f9554;
  --error-red: #c91634;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Added Font face for Jost Family */
@font-face {
  font-family: "Jost-Regular";
  src: url("./assets/fonts/Jost/static/Jost-Regular.ttf");
}

@font-face {
  font-family: "Jost-Bold";
  src: url("./assets/fonts/Jost/static/Jost-Bold.ttf");
}

@font-face {
  font-family: "Jost-SemiBold";
  src: url("./assets/fonts/Jost/static/Jost-SemiBold.ttf");
}

@font-face {
  font-family: "Jost-Medium";
  src: url("./assets/fonts/Jost/static/Jost-Medium.ttf");
}

@font-face {
  font-family: "Jost-Light";
  src: url("./assets/fonts/Jost/static/Jost-Light.ttf");
}

@font-face {
  font-family: "Jost-Light";
  src: url("./assets/fonts/Jost/static/Jost-Light.ttf");
}

@font-face {
  font-family: "Jost-Italic";
  src: url("./assets/fonts/Jost/static/Jost-Italic.ttf");
}

html,
body {
  margin: 0;
  font-family: "Jost-Regular", sans-serif;
  font-size: 100%;
  min-height: 100vh;
  width: 100%;
  position: relative;
  /* overflow: auto; */
  background-color: var(--white-color);
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

#root {
  height: 100vh;
  min-height: 100vh;
}

/* SCROLL BAR CUSTOMIZATION STYLES */
::-webkit-scrollbar {
  background: #f1f1f1;
  border-radius: 4px;
  height: 8px;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 6px;
}

/* Password Bullet styling */
input[type="password"] {
  font: small-caption;
  font-size: 17px;
  font-weight: 500;
}

/* Textfield autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  background: none;
}

.main-container {
  min-height: 100%;
  padding: 20px 50px;
  max-width: inherit;
  /* overflow: auto; */
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-action-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.active-checkbox {
  color: var(--black-color) !important;
}

.password-eye-icon {
  color: var(--quinary-black-color);
}

/* TABLE STYLES */
.table-container {
  box-shadow: none;
  margin-top: 20px;

  /* height: calc(100% - 475px); */
}

.table-header-row {
  border-top-color: var(--quinary-gray-color);
  border-top-width: 1px;
  border-top-style: solid;
  background-color: var(--white-color);
}

.table-header-text {
  color: var(--tertiary-theme-color);
  font-size: 1rem;
  font-weight: bold;
}

.table-data-text {
  color: var(--tertiary-black-color);
  font-size: 1rem;
}

.table-data-row {
  border-top-color: var(--white-color);
  border-top-width: 4px;
  border-top-style: solid;
  border-bottom-color: var(--white-color);
  border-bottom-width: 4px;
  border-bottom-style: solid;
  background-color: var(--light-gray-color);
  border-radius: 10px;
}

.selected-items-count-text {
  font-size: 1.125rem;
  font-style: italic;
  font-weight: normal;
  font-family: "Jost-Italic";
  color: var(--quinary-blue-magenta-color);
  margin-left: 20px;
}

.clear-selected-text {
  margin-left: 15px;
  font-family: "Jost-Regular";
  font-size: 1.125rem;
  color: var(--quaternary-black-color);
  text-decoration: underline;
  cursor: pointer;
}

/* TABLE FILTER STYLES */

.clear-filter {
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
}

.filter-heading-holder {
  display: flex;
  align-items: center;
}

.filter-heading {
  color: var(--secondary-dark-gray-color);
  font-size: 1rem;
  margin-right: 15px;
}

.filters-action-holder {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* remove this */
.filters-holder {
  display: flex;
  align-items: center;
}

.filter-dropdown {
  /* min-width: 120px; */
  margin-right: 12px;
  width: 100%;
}

.action-dropdown {
  width: 150px;
  margin-right: 15px;
}

.time-sync-holder {
  display: flex;
  align-items: center;
  float: right;
}

.float-right {
  float: right;
}

/* TABLE ACTION ICONS STYLES */

.table-action-icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.delete-icon {
  color: var(--primary-red-color);
}

.edit-icon {
  color: var(--quaternary-black-color);
}

.view-icon {
  color: var(--quaternary-black-color);
}

.duplicate-icon {
  color: var(--quinary-theme-color);
}

.heading {
  font-size: 1.5rem;
  color: var(--quaternary-black-color);
  font-family: "Jost-Regular";
  font-weight: normal;
}

.accordion-heading {
  font-size: 1.875rem;
  color: #494a67;
  font-family: "Jost-Regular";
  font-weight: normal;
}

.dropdown-icon {
  color: var(--secondary-dark-gray-color);
  margin-right: 10px;
}

/* BUTTON RELATED STYLES  */
.btn {
  color: var(--white-color);
  border-radius: 6;
  height: 49px;
  text-transform: capitalize;
  font-family: "Jost-Regular";
  font-size: 1rem;
}

.btn-dark,
.btn-dark:hover {
  background-color: var(--black-color);
  border: 1px solid var(--black-color);
}

.btn-dark-outline,
.btn-dark-outline:hover {
  border: 1px solid var(--secondary-theme-color);
  background-color: var(--white-color);
  color: var(--secondary-theme-color);
}

.btn-light-outline,
.btn-light-outline:hover {
  border: 1px solid var(--medium-gray-color);
  color: var(--dark-gray-color);
  background-color: var(--white-color);
}

.btn-sync,
.btn-sync:hover {
  background-color: #f1f2ff;
  border: 0.5px solid #494a67;
  color: var(--quinary-black-color);
  padding: 8px 4px;
  min-width: 50px;
}

.btn-disabled {
  border: none;
  color: var(--white-color) !important;
}

.login-btn {
  width: 100%;
  border-radius: 8px;
  margin-top: 40px;
  font-size: 1.375rem;
}

/* UPDATED TIME STYLES */
.updated-on-heading {
  font-size: 1rem;
  color: var(--secondary-dark-gray-color);
}

/* MARGINS */

.margin-30,
.margin-30px {
  margin: 30px;
}

.margin-right-5,
.margin-right-5px {
  margin-right: 5px;
}

.margin-left-5,
.margin-left-5px {
  margin-left: 5px;
}

.margin-right-10,
.margin-right-10px {
  margin-right: 10px;
}

.margin-left-10,
.margin-left-10px {
  margin-left: 10px;
}

.margin-right-15,
.margin-right-15px {
  margin-right: 15px;
}

.margin-left-15,
.margin-left-15px {
  margin-left: 15px;
}

.margin-left-24,
.margin-left-24px {
  margin-left: 24px;
}

.margin-top-20,
.margin-top-20px {
  margin-top: 20px;
}

.margin-top-25,
.margin-top-25px {
  margin-top: 25px;
}

.margin-top-30,
.margin-top-30px {
  margin-top: 30px;
}

.margin-top-50,
.margin-top-50px {
  margin-top: 50px;
}

.margin-bottom-20,
.margin-bottom-20px {
  margin-bottom: 20px;
}

.margin-bottom-30,
.margin-bottom-30px {
  margin-bottom: 30px;
}

/* Dashboard Related Styles */
.graph-container-heading {
  font-family: "Jost-Medium";
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--tertiary-theme-color);
}

.graph-data-container {
  background-color: var(--quaternary-white-color);
  border-radius: 6px;
  padding: 20px;
}

.graph-heading {
  font-family: "Jost-Regular";
  font-size: 1.625rem;
  font-weight: 300;
  color: var(--quinary-blue-magenta-color);
  margin-bottom: 25px;
}

.graph-holder {
  background-color: var(--quaternary-white-color);
  height: 100%;
  width: 100%;
  position: relative;
}

/* .grap-stats {
  position: absolute;
  top: 10px;
  z-index: 10;
  background-color: red;
} */

.stat-card {
  background-color: var(--white-color);
  width: 100%;
  min-height: 150px;
  border-radius: 5px;
  padding: 12px;
  position: relative;
  cursor: pointer;
}

.total-stats-holder {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.individual-stats-holder {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.individual-stats-holder-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.individual-stat-card-heading {
  color: var(--quaternary-black-color);
  font-family: "Jost-Medium";
  /* font-size: 1.312rem; */
  font-size: 1rem;
  font-weight: 500;
}

.stat-card-total-heading {
  color: var(--quaternary-black-color);
  font-family: "Jost-Regular";
  font-size: 1.875rem;
  text-align: center;
}

.stat-card-total-value {
  font-family: "Jost-Regular";
  font-size: 3.875rem;
  /* font-size: 3rem; */
  margin-right: 20px;
  color: var(--quaternary-black-color);
}

.individual-stat-value-text {
  width: 36px;
  height: 90px;
  font-family: "Jost-Regular";
  font-size: 2.625rem;
}

.red-stat-value {
  color: #f37c7c;
}

.green-stat-value {
  color: #54b652;
}

.blue-stat-value {
  color: #8276ff;
}

.green-dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #54b652;
  margin-right: 15px;
}

.red-dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #f37c7c;
  margin-right: 15px;
}

.no-record {
  width: 100%;
  position: absolute;
  top: 100px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 18px;
}

.textfield-style {
  margin-left: 30px;
  margin-right: 30px;
}

.heading-tooltip-text {
  padding: 0 12px;
  max-width: 256px;
}

.individual-stat-card-icon {
  position: absolute;
  top: 1px;
  right: 1px;
  color: #c4c4ce;
}

.infooutlined-alignment {
  margin-right: -20px;
  color: #676ac0;
  height: 20px;
  cursor: pointer;
}
