.priority-switch-btn-alignment{
    width:8rem;
    margin-right: -20px;
}

.infooutlined-alignment{
    margin-right: -20px;
    color: #676ac0;
    height: 20px
}

.update-image-size-fix{
height: 70px;
width:110px;
margin-right: 10px;
}

.update-image-text-fix{
  margin-left: 120px;
  margin-top: -40px; 
}
