.insertMedia {
  margin-bottom: 1.5rem;
  display: flex;
  
}

.divider {
  margin-top: 0.5rem;
  margin-bottom: 1.8rem;
}

.textfield-container {
  margin-bottom: 1.8rem;
}
