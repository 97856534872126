.priority-switch {
    width: 6rem;
}

.infooutlined-alignment {
    color: #676ac0;
    height: 20px;
}

.priority-holder {
    display: flex;
    align-items: center;
    float: left
}

.accordian-global-padding {
    padding: 20px;
}

.Avator {
    width: 0.9rem;
    height: 0.9rem;
    cursor: pointer;
}

.QuillEditor {
    background-color: #f6f6fa;
    /* height: 5rem; */
}

.Upload-heading {
    padding-bottom: 1rem;
}

.button-position {
    float: right;
}