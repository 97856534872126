.retry-container {
  padding: 20px;
  width: 500px;
  min-height: 300px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 0px 20px 2px var(--tertiary-gray-color);
}

.retry-container > img {
  height: 50%;
  width: 60%;
  object-fit: contain;
}

.retry-container > h2 {
  text-align: center;
  padding-top: 15px;
  color: var(--error-red);
  position: relative;
}

.retry-container > h2::after {
  content: "";
  display: block;
  bottom: -4px;
  left: 0;
  right: 0;
  margin: auto;
  width: 70%;
  border-bottom: 2px solid var(--error-red);
  position: absolute;
}

.retry-container > p {
  text-align: justift;
  padding: 15px;
}
