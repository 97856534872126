.heading-text-alignmnet {
    margin-top: 40px;
    margin-bottom: 40px;
}

.input-label-alignmnet {
    width: 450px;
    margin-top: 10px;
}

/* .save-btn{
    text-align:right;
    margin-top:20px;
    } */

.home-page-heading {
    margin-bottom: 15px;
    ;
}

.info-icon {
    height: 20px;
    width: 20px;
}

.Divider {
    margin: 0 -24px
}