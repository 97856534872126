.project-image {
  width: 60px;
  height: 45px;
  object-fit: contain;
}

.create-content-link {
  color: var(--quinary-theme-color);
  font-size: 1rem;
  text-decoration: underline;
}

.tab-heading {
  border-bottom: 1;
  border-color: "divider";
  /* width: 50% !important; */
  margin-left: 30px;
}
.media-container{
  height: "70px";
 width: "110px"
}
.tab-heading-label {
  text-transform: capitalize;
  font-size: 0.7rem;
  /* color: var(--quaternary-black-color); */
  font-family: "Jost-Regular";
}
