.info-icon {
    height: 20px;
    width: 20px;
}

.rera-document-container {
    margin-top: 20px;
    border: 1px solid #0000002f;
    border-radius: 10px;
}

.divider,
.divider-with-color {
    margin: 0px 0px 20px -16px;
}

.divider-with-color {
    border-color: var(--quaternary-gray-color)
}

.pdf-upload-section {
    text-align: -webkit-right
}

.rera-phase-section {
    margin-top: 10px;
    align-items: flex-start;
    padding-left: 25px;
}

.delete-icon {
    color: #ff485a;
    cursor: pointer;
}

.Mui-link-btn {
    color: var(--quaternary-black-color);
    font-family: "Jost-Medium";
    font-size: 1rem;
}