.inputStyle::placeholder {
  position: relative;

  font-size: 15px;
  z-index: 99999;
  width: 200px;
  text-align: center;
}
.searchIcon {
  position: relative;
  left: 40px;
  top: 10px;
}
.inputStyle:focus {
  width: 250px !important;
  text-align: center;
  font-size: 16px;
}
