.texfield-style {
  margin-left: 30px;
  margin-right: 30px;
}

.section-heading {
  margin: 0px 30px 30px 30px;
  color: #494a67;
  font-family: "Jost-Regular";
  font-size: 1.875rem;
  font-weight: normal;
}

.section-content {
  padding: 30px;
}

.info-icon {
  height : 20px;
  width : 20px;
}

.section-heading-with-switch {
  margin: 0px 30px 30px 30px;
  color: #494a67;
  font-family: "Jost-Regular";
  font-size: 1.875rem;
  font-weight:lighter;
}

