  
  .dialog-actions {
    text-align:end;
  }

  .okay-btn{
    font-size :1.3rem;
    font-weight: 600;
    color : var(--primary-theme-color)
}

.retry-button-section{
  padding-top : 50px;
  height : 200px;
  text-align : center;
  align-items: center;
  justify-content: center;
}

.retry-btn {
  margin-top : 10px;
}
  