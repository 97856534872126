.accordion-global-padding {
  padding: 20px;
}

.general-info-postion {
  position: relative;
}

.time-in-min {
  margin-top: 17px;
}

.stack-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.info-icon {
  height: 20px;
  width: 20px;
}