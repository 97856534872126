.info-icon {
    height : 20px;
    width : 20px;
}

.project-image {
    width: 60px;
    height: 45px;
    object-fit: contain;
  }

  .project-list-image{
    width : 140px;
    height : 80px;
    margin : -25px 0;
}

.info-icon {
    height : 20px;
    width : 20px;
}

.divider-section {
    margin : 0 -57px;
}

.save-btn-section {
    text-align : end;
    margin : 10px 0;
}

.remove-text{
    text-decoration : none
}

.sections-division{
    margin-top : 20px;
    margin-bottom : 20px
}

.stack-container{
    margin-top : 20px;
    margin-bottom : 20px;
}

.section-heading{
    padding-bottom : 10px
}

.submit-btn-section{
    margin : 20px 0
}

.heading-text-color {
    color : #494a67
}

.mapping-project-btn {
    margin-bottom : 10px
}



