.sidemenu {
  width: var(--sidemenu-width);
  height: calc(100% - var(--header-height));
  overflow-y: auto;
  background: var(--white-color);
  position: fixed;
  top: var(--header-height);
  float: left;
  background-color: var(--primary-theme-color);
}

.menu-without-children {
  background-color: var(--primary-theme-color);
  height: 50px;
  width: 100%;
  /* font-size: 1.25rem; */
  font-size: 1.125rem;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 16px;
  color: var(--white-color);
}

.menu-without-children-active {
  background-color: var(--white-color);
  color: var(--black-color);
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1.125rem;
  /* font-size: 1.25rem; */
  font-weight: 500;
  padding-left: 40px;
}

.menu-without-children-active::before {
  content: "";
  position: absolute;
  left: 15px;
  top: 22px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: inline-block;
  background-color: var(--black-color);
}

.menu-header {
  background-color: var(--primary-theme-color);
  box-shadow: none;
  margin-bottom: 8px;
}

.menu-header::before {
  background-color: var(--primary-theme-color);
}

.menu-header-text {
  /* color: var(--primary-blue-magenta-color); */
  /* font-size: 1.25rem; */
  font-size: 1.125rem;
  color: var(--tertiary-white-color);
}

.menu-details {
  padding: 0px;
}

.expand-collapse-icon {
  color: var(--tertiary-white-color);
}

.menu-item {
  position: relative;
  background-color: var(--secondary-theme-color);
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 40px;
  font-size: 1rem;
  color: var(--white-color);
}

/* .menu-item:hover {
  background-color: var(--primary-theme-color);
  color: var(--black-color);
} */

/* .menu-item:hover::before {
  content: "";
  position: absolute;
  left: 15px;
  top: 16px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: inline-block;
  background-color: var(--black-color);
} */

.menu-item-active {
  background-color: var(--white-color);
  color: var(--black-color);
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 40px;
}

.menu-item-active::before {
  content: "";
  position: absolute;
  left: 15px;
  top: 16px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: inline-block;
  background-color: var(--black-color);
}
/* leads menu style */
.leadsMenuActive {
  background-color: var(--white-color);
  color: var(--black-color);
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 40px;
  color: black !important;
}
.leadsTextActive {
  position: relative;
  left: 20px;
}

.leadsMenuActive::before {
  content: "";
  position: absolute;
  left: 15px;
  top: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: inline-block;
  background-color: var(--black-color);
}
