.stat-card-total-icon {
  position: absolute;
  top: 9px;
  right: 9px;
  color: #c4c4ce;
}

.total-stats-holder {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--white-color);
  width: 100%;
  height: 150px;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}

.individual-stats-holder {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white-color);
  width: 100%;
  height: 150px;
  border-radius: 5px;
  padding: 15px;
  position: relative;
  cursor: pointer;
}

.individual-stat-card-icon {
  position: absolute;
  top: 1px;
  right: 1px;
  color: #c4c4ce;
}

.graph-inside-data {
  font-weight: bold;
  font-family: "Jost-Regular";
  color: black;
  font-size: 19px;
}

.graph-inside-holder {
  position: absolute;
  top: 130px;
  left: 129px;
}

.graph-container-holder {
  position: relative;
}
