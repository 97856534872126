.accordian-global-padding {
  padding: 20px;
}

.priority-switch {
  width: 8rem;
}

.infooutlined-alignment {
  color: #676ac0;
  height: 20px;
}

.priority-holder {
  display: flex;
  align-items: center;
  float: left;
}

.button-position {
  float: right;
}

.Upload-heading-padding {
  padding-bottom: 1rem;
}

.Avator {
  width: 0.9rem;
  height: 0.9rem;
  cursor: pointer;
}
