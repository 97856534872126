.field-container-box {
  padding: 1.5rem 1.5rem 0.5rem;
  justify-content: flex-start;
  column-gap: 1.2rem;
}
.section-gutter {
  padding: 1.5rem;
}
.chat-box {
  border: 1px solid var(--secondary-dark-gray-color);
  border-radius: 0.4rem;
  margin: 30px 0;
}
.field-box {
  padding: 1.5rem 1.5rem 0.5rem;
  /* column-gap: 1.2rem; */
}