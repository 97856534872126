.confirm-dialog-alert {
    margin-bottom: 24px;
  }
  
.image-dropdown-section {
  background-color: rgb(251, 247, 247);
  height: 240px;
  width : 320px;
}

.textfield-section {
  width : 600px;
}

.text-select-elements-wrapper {
  display : flex;
  flex-direction : column;
  justify-content : space-between;
  height : 160px;
}

.flex-col-space-btn {
  display : flex;
  flex-direction : column;
  justify-content : space-between;
}

.upload-btn-wrapper {
  text-align: end;
  margin-top : 50px;
}

.upload-btn {
  width : 96px;
}

.display-flex-space-btw{
  display : flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-section {
  display : flex;
  flex-direction : row;
  margin:0 16px;
}

.close-icon-btn{
  width : 48px;
  height : 32px;
}

.close-icon-btn-wrapper {
  position : absolute;
  top : 0;
  right : 0;
  z-index: 1;
}

.replace-button-wrapper{
  position : relative;
  float : right;
  bottom : 32px;
  right : 5px;
  /* background-color :white; */
  border : none;
  width : 150px;
  height : 27.2px;
  align-items : center;
}

.cached-icon{
  margin-right : 8px;
}

.replace-text{
  margin-right : none;
}

.preview-image-section{
  height : 240px;
  width : 320px;
  object-fit : cover;
}

.image-upload-input {
  position: absolute;
  height : 100%;
  width : 100%;
  top : 0;
  left : 0;
  opacity : 0;
}

.upload-icon {
  height : 30px;
  width : 30px;
}

.browse-files-link{
  color : #000;
  font-size : 1rem;
  text-decoration : underline;
  cursor : pointer;
}

.input-display-none{
  display : none;
}

.media-upload-content {
  margin-right : 48px; 
  max-height : 320px;
  width : 380px;
}

.right-side-icon{
  background-color: var(--quaternary-white-color);
  height: 30px;
  cursor : pointer;
  
}

.replace-btn{
  background-color: white;
  height : 30px
}

.replace-btn:hover {
  background-color: white;
}

.upload-type-radio{
  margin-left : 20px;
  margin-bottom : 10px
}

.info-icon {
  height : 20px;
  width : 20px;
}

.page-with-switch-section {
  margin-top : -20px;
  margin-left : 10px;
}

.video-thumbnail-text{
 text-align : flex-start !important;
 margin-right : 130px;
 margin-bottom : 10px
}

.textfield-wrapper{
  margin-bottom : 20px;
}

.MuiDialogContent-root {
  padding : none !important;
}