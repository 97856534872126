.no-data-container {
  width: 100%;
  height: 300px;
  padding: 20px;
  margin-top: 30px;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.image-holder {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: var(--secondary-white-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-holder > img {
  object-fit: contain;
  height: 60px;
  width: 60px;
}

.no-data-message {
  text-align: center;
  font-size: 1.25rem;
  color: var(--dark-gray-color);
}
