.stat-card-total-icon {
    position: absolute;
    top: 9px;
    right: 9px;
    color: #c4c4ce;
}

.individual-stat-card-icon {
    position: absolute;
    top: 1px;
    right: 1px;
    color: #c4c4ce;
}