.bulk-upload-content {
    margin-top : 15px;
}

.bulk-upload-text{
    color : var(--tertiary-theme-color);
    font-size: 1.4rem;
    font-weight: 600;
}

.download-template-section{
    margin-top : 20px;
    width : 800px;
}

.download-icon{
    margin-bottom : 5px;
}

.download-link-text{
    color : blue;
    font-size : 1.25rem;
}

#upload-btn-section{
    margin-top : 9px
}

.input-section{
    background-color : var(--tertiary-white-color);
    width : 450px;
}

::-webkit-file-upload-button{
    position : relative;
    height : 40px;
    margin-top : -15px;
    margin-bottom : 50px;
    margin-left : -14px;
    margin-right : 10px;    
    border : 1px solid var(--quaternary-gray-color);
    width : 120px;
    border-radius : 5px;
    background-color :white;
}

.cancel-icon{
    color : var(--primary-red-color);
}

.table-heading-text {
    color : var(--black-color);
    font-size: 0.9rem;
    font-weight : bolder; 
}

.file-link {
    color : black;
    font-size : 1rem;
}

.date-time {
    font-size : 1rem;
}

