.customer-name-heading{
    font-size: 1.3rem;
    font-family: "Jost-Regular";
    color: var(--black-color);
    font-weight:500
  }
    
  .key-typography {
    font-size: 1.125rem;
    color: #707070;
  }
  
  .value-typography {
    font-size: 1.125rem;
    color: #141615;
    font-weight:500
  }  

  .heading-box-container {
    margin : 10px 0px 0px 37px
  }
