.basic-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    padding: 0 20px
}

td,
th {
    border: 1px solid #17171c;
    text-align: left;
    padding: 10px 13px;
    color: #000000
}

th {
    background-color: #e4e4e4;
}

.label-typography {
    color: #686680;
    font-size: 1.125rem;
}

.value-typography {
    color: #000000;
    font-size: 1.125rem;
    min-width: 220px;
}