.my-profile-heading {
    font-size: 1.5rem;
    font-family: "Jost-Regular";
    color: var(--black-color);
  }
  
  .contact-details {
    color: #141615;
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .key-typography {
    font-size: 1.125rem;
    color: #707070;
    margin-bottom: 30px;
  }
  
  .value-typography {
    font-size: 1.125rem;
    color: #141615;
  }
  
  .title {
    color: #141615;
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  