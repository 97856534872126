.btn-content {
  display: flex;
  justify-content: space-between;
  width: 320px;
}

.btn-clr {
  color: var(--secondary-theme-color);
  margin: 0 32px;
  width: 128px;
}

.btn-clr:hover {
  border-color: var(--secondary-theme-color);
}

.btn-delete {
  display: flex;
  justify-content: space-between;
}

.txt-input {
  margin: 20px;
}

.text-field-lebel {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.media-drags-wrapper {
  margin-top: 16px;
  width: 320px;
  height: 224px;
}

.span-infooutlined {
  margin-left: 16px;
  color: #5348d0;
}

.infoutlined-color {
  color: #5348d0;
}

.btn-save {
  display: flex;
  justify-content: space-between;
}

.delete-icon {
  margin-left: 16px;
}

.select-dropdown {
  background-color: #f2f0ff;
  width: 15.4rem;
  margin-bottom: 24px;
}

.select-option {
  height: 48px;
}

.testomiial-heading {
  font-size: 20px;
  color: #242e4c;
}

.card-color {
  font-size: 30px;
  color: #b0afbc;
  margin-left: 10px;
}

.horizontal-line {
  margin-top: 16px;
}

.select-options-validate {
  background-color: white;
}

.title-heading-alignment {
  margin-bottom: 12.8px;
}

.form-max-width {
  max-width: 250px;
}

.subtitle-margin-alignment {
  margin-left: -160px;
}

/* This style is needed check whether top styles are required */
.infooutlined-alignment {
  color: #676ac0;
  height: 20px;
  margin-left: 10px;
}
.descrition-radio-btn {
  margin-bottom: 10px
}
