.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-theme-color);
  /* padding: 100px 0px; */
  overflow: auto;
}

.login-card {
  background-color: var(--white-color);
  /* width: 650px; */
  width: 600px;
  border-radius: 20px;
  padding: 50px 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0 12px 24px 0 rgba(227, 225, 255, 0.4);
}

.logo {
  height: auto;
  /* width: 230px; */
  width: 200px;
  object-fit: contain;
}

.login-heading {
  font-size: 1.625rem;
  color: var(--tertiary-black-color);
  margin-top: 40px;
}

.textfiled-holder {
  width: 100%;
  margin-top: 40px;
}

.email-password-textfield {
  width: 100%;
}

.password-textfield {
  margin-top: 30px;
}

.forgot-password-link {
  margin-top: 30px;
  font-size: 1.25rem;
  font-weight: 500;
  font-family: "Jost-Medium";
  color: var(--primary-theme-color);
  user-select: none;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 800px) {
  .login-card {
    width: 90%;
  }
}
