.scroll-top-btn {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: fixed;
  z-index: 999;
  right: 50px;
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--secondary-theme-color);
}

.hide-scroll-top-btn {
  display: none;
}
