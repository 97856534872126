.display-flex-space-btw {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .heading-action-text {
    color: var(--tertiary-theme-color);
    font-weight: 500;
    font-size: 1.13rem;
  }
  
  .heading-btn-wrapper {
    width: 320px;
  }
  
  .card-count {
    color: #b0afbc;
  }
  
  .divider {
    margin-top: 16px;
  }
  
  .delete-btn-icon {
    margin-left: 16px;
  }
  
  .textfield-section {
    margin-top: 32px;
    display: flex;
  }
  
  .info-oulined-icon {
    color: var(--quinary-theme-color);
  }
  
  .form-content-wrapper {
    border: 1px solid var(--primary-theme-color);
    position: relative;
    margin-top: 16px;
    border-radius: 5px;
    padding: 16px;
  }
  
  .info-icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .textfield-characters-count {
    text-align: end;
    font-size: 14px;
    color: var(--quinary-theme-color);
  }
  
  .sub-title-field {
    width: 35%;
    margin-left: 32px;
  }
  
  .referral-media-wrapper {
    margin: 24px 8px 0 0;
  }
  
  .referral-media-text {
    width: 200px;
  }
  
  .drag-and-drop-section {
    width: 320px;
    height: 240px;
    margin-top: 16px;
  }
  
  .inner-form-wrapper {
    padding: 8px;
  }
  