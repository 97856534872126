.create-content-link {
    color: var(--quinary-theme-color);
    font-size: 1rem;
    color: black;
    font-weight: bold;
}

.project-image {
    width: 110px;
    height: 70px;
    object-fit: contain;
}