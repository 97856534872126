.okay-btn{
    font-size :1.3rem;
    font-weight: 600;
    color : var(--primary-theme-color)
}

.pending-payment-dialog-header{
    padding : 10px 10px
}

.dialog-content-section {
    text-align: center;
    align-items: center;
}

.pending-amount-mention-section{
    background-image: linear-gradient(101deg, #faf4d1 5%, #f4e8a9 93%);
    margin: 0px 30px;
    padding : 20px 0px;
    border-radius : 10px;
}

.font-weight-bold {
    font-weight: bold;
}

.dashed-divider{
    border-bottom : 0.7px dashed #e3e2f0;
    margin-top : 15px;
}

.copy-text {
    font-size: 1.2rem;
    color : #676ac0;
    text-decoration: underline;
    cursor : pointer;
}