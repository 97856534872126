.avator {
    width: 0.9rem;
    height: 0.9rem;
    cursor: pointer;
}
.container-padding{
    padding: 30px;
}
.ResetButton{
    padding: 13px 40px;
}
.alignCenter-imageDropBox{
    align-items: center;
}
.verticleAlign-imageDropBox{
    margin-left: auto;
}