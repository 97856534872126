.dialog-title-holder {
    padding: 40px 76px 10px 76px;
}

.project-image {
    width: 140px;
    height: 80px;
    margin: -15px 0;
}

.search-input {
    width: 100%;
}

.selected-projects-count-text {
    font-size: 1rem;
    font-style: italic;
    font-weight: normal;
    color: var(--secondary-dark-gray-color);
    margin-left: 20px;
}

.dialog-table-pagination {
    padding-right: 76px;
}

.clear-filter-text {
    font-family: "Jost-Medium";
    font-size: 1.125rem;
}