.info-icon {
    height : 20px;
    width : 20px;
    cursor : pointer;
}

.top-upload-btn{
    margin : 40px 0;
}

.content-divider{
    margin : 0 -56px
}
