.info-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-top: 10px;
}

.divider {
  background-color: grey;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.preview-image-section {
  height: 240px;
  width: 320px;
  object-fit: cover;
}

.heading-tooltip-text {
  padding: 0 12px;
  max-width: 256px
}

.descrition-radio-btn {
  margin-bottom: 10px
}

.media-section {
  margin-bottom: 5px
}

.quill-editor {
  height: 197px;
  background-color: red;
}

.detailed-text {
  margin-top: 10px
}

.createdby-detailes {
  margin-left: 35px
}

.cursor-pointer {
  cursor: pointer;
}