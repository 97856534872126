.customer-name-heading{
    font-size: 1.5rem;
    font-family: "Jost-Regular";
    color: var(--black-color);
  }
    
  .kye-typography {
    font-size: 1.125rem;
    color: #707070;
    margin-bottom: 30px;
  }
  
  .value-typography {
    font-size: 1.125rem;
    color: #141615;
  }  