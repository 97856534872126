.dialogBox-outer {
  position: absolute;
  width: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white-color);
  border-radius: 8px;
  padding: 23px 33px 23px;
}

.dialog-title {
  padding: 0px 0px 10px 0px !important;
}

.backgroundblur {
  backdrop-filter: blur(8px);
}
