.page {
  width: 100%;
  min-height: 100%;
}

.root {
  display: flex;
}

.main-container {
  margin: 0px 0px 10px var(--sidemenu-width);
  padding-top: var(--header-height);
  padding-bottom: 20px;
}
