.insight-media {
  width: 60px;
  height: 45px;
  object-fit: contain;
}
.Avator {
  width: 0.9rem;
  height: 0.9rem;
  cursor: pointer;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
.Media-box {
  height: "70px";
  width: "110px";
}
.Arrow-icon-center {
  vertical-align: bottom;
}
.ResetButton {
  padding: 13px 40px;
}
.NoDataMassage {
  justify-content: center;
}
.RemoveOverFlow {
  overflow-x: none;
}

.media-container {
  position: relative;
}

.trash-icon {
  position: absolute;
  left: 25%;
  top: 0;
}
