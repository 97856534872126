.confirm-dialog-alert {
    margin-bottom: 24px;
  }
  
.image-dropdown-section {
  background-color: rgb(251, 247, 247);
  height: 240px;
  width : 320px;
}

.textfield-section {
  width : 480px;
}

.text-select-elements-wrapper {
  display : flex;
  flex-direction : column;
  justify-content : space-between;
  height : 160px;
}

.flex-col-space-btn {
  display : flex;
  flex-direction : column;
  justify-content : space-between;
}

.upload-btn-wrapper {
  text-align: end;
  margin-top : 50px;
}

.upload-btn {
  width : 96px;
}

.display-flex-space-btw{
  display : flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-section {
  display : flex;
  flex-direction : row;
  margin:0 16px;
}

.close-icon-btn{
  width : 48px;
  height : 32px;
}

.close-icon-btn-wrapper {
  position : absolute;
  top : 0;
  right : 0;
  z-index: 1;
}

.replace-button-wrapper{
  position : relative;
  float : left;
  bottom : 32px;
  left : 5px;
  background-color : var(--quaternary-white-color);
  border : none;
  width : 100px;
  height : 27.2px;
  align-items : center;
  cursor : pointer;

}

.replace-button-wrapper:hover {
  background-color :var(--quaternary-white-color);
  cursor : pointer;
}

.cached-icon{
  margin-right : 8px;
}

.replace-text{
  margin-right : none;
}

.preview-image-section{
  height : 240px;
  width : 320px;
  object-fit : cover;
}

.image-upload-input {
  position: absolute;
  height : 100%;
  width : 100%;
  top : 0;
  left : 0;
  opacity : 0;
}


.upload-icon {
  height : 30px;
  width : 30px;
}

.browse-files-link{
  color : #4a39ff;
  font-size : 1rem;
  text-decoration : underline;
  cursor : pointer;
}

.input-display-none{
  display : none;
}

.media-upload-content {
  margin-right : 48px;
  max-height : 320px;
}

.right-side-button {
  position : relative;
  float : right;
  bottom : 32px;
  right : 5px;
  /* background-color :white; */
  border : none;
  width : 100px;
  height : 24px;
  align-items : center;
}

.right-side-icon{
  background-color: var(--quaternary-white-color);
  height: 30px;
  cursor : pointer;
  padding : 0 5px;
}

.right-side-icon:hover{
  border : 1px solid var(--secondary-theme-color)
}

.delete-icon {
  color : red
}

.delete-icon{
  margin-left : 3px
}

.upload-media-btn:hover{
  margin : 10px;
}

.upload-btn-input {
  background-color : #000;
  padding : 3px 10px;
  width : 160px;
  border-radius : 5px;
  cursor : pointer;
}

.upload-btn-input-hover {
  background-color : rgb(77, 75, 75);
  padding : 3px 10px;
  width : 160px;
  border-radius : 5px;
  cursor : pointer;
}

