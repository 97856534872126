.flash-message-dialog-actions {
  margin-right: 16px;
  padding-bottom: 20px;
}

.close-icon {
  float: right;
  cursor: pointer;
  color: var(--medium-gray-color);
}

.content-alignment {
  text-align: center;
}

.content {
  text-transform: capitalize;
}

.success-heading {
  color: var(--success-green);
  margin-bottom: 20px;
}

.error-heading {
  color: var(--error-red);
  margin-bottom: 20px;
}

.success-error-image {
  height: 60px;
  width: 60px;
  margin-bottom: 20px;
}

.warning-heading {
  color: #fad107;
  margin-bottom: 20px;
}
