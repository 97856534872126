.journey-section-container {
    margin-top : 24px;
    padding : 24px 24px 10px 24px;
    position : relative;
    border-radius : 10px;
    color : grey !important;
}

.completed-journey-section-container {
  margin-top : 24px;
  padding : 24px 24px 10px 24px;
  position : relative;
  border-radius : 10px;
  color : grey !important;
}

.journey-completed-section-container {
  margin-top : 24px;
    border : 2px solid var(--quaternary-blue-magenta-color);
    padding : 24px 24px 10px 24px;
    position : relative;
    border-radius : 10px;
    color : grey !important;
}

.disabled-journey-section{
  margin-top : 24px;
  padding : 24px 24px 10px 24px;
  position : relative;
  border-radius : 10px;
  color : rgb(244, 241, 241) !important;
}

.content-view-option {
    color : #676ac0;
    text-decoration: underline;
    cursor : pointer
}

.rupee-coin {
    height : 30px;
    width : 30px;
}

.application-date {
    width: 107px;
    height: 24px;
    margin: 2px 0 0 16px;
    font-family: Jost;
    font-size: 16.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #383838;
  }

  .divider-line{
    margin-top : 10px;
    margin : 0px -24px;
  }

  .divider-line-grey{
    margin-top : 10px;
    margin : 0px -24px;
    border-color : var(--light-gray-color)
  }

  .divider-main-container {
    margin-top : 10px;
    margin : 0px -24px;
    border-color: rgb(164, 163, 163);
  }

  .divider-line-children{
    margin-top : 10px;
    margin : 0px -24px;
    margin-left : -54px;
    border-color : var(--light-gray-color)
  }

  .disabled-text {
    color : #cececf
  }

  .disabled-content-view-option{
    color : #cececf;
    text-decoration: underline;
    cursor : pointer
  }
