.info-icon {
    width: 20px;
    height: 18px;
}

.view-replace-wrapper {
    border: 1px solid #303030;
    padding: 15px;
    border-radius: 10px;
    min-width: 400px;
}

.link-a-tag {
    color: var(--primary-theme-color);
}

.replace-button {
    color: var(--primary-theme-color);
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
}

.loader-content {
    align-items: center;
    text-align: center;
}