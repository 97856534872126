.breadcrumbs-container {
  background-color: var(--secondary-gray-color);
  flex: 1;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.separator {
  color: var(--senary-blue-magenta-color);
}

.active-breadcrumb {
  font-size: 1rem;
  color: var(--quaternary-black-color);
  font-weight: 600;
  font-family: "Jost-Regular";
}

.breadcrumb-route {
  color: var(--quinary-blue-magenta-color);
  text-decoration: none;
  font-size: 1rem;
  font-family: "Jost-Regular";
}

.breadcrumb-route:hover {
  text-decoration: underline;
}

.search-icon {
  width: 20px;
}
