.confirm-dialog-alert {
    margin-bottom: 24px;
}

.confirm-dialog-actions {
    padding-bottom: 35px;
}

.action-btn {
    min-width: 100px;
}

.typography-medium {
    font-family: "JOST-Medium";
    font-size: 1.25rem
}

.typography-semiBold {
    font-family: "JOST-semiBold";
    font-size: 1.35rem;
}

.typography-Light {
    font-family: "JOST-Light";
    font-size: 1.15rem;
}