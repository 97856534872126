.page-heading-container {
  background-image: linear-gradient(
    to left,
    rgba(246, 246, 249, 0),
    #eaeaf1 0%
  );
  width: 100%;
  /* padding: 15px 42px; */
  padding: 10px 30px;
}

.page-heading {
  font-size: 1.625rem;
  font-family: "Jost-Regular";
  color: var(--black-color);
}
