.confirm-dialog-alert {
  margin-bottom: 24px;
}

.image-dropdown-section {
  background-color: rgb(251, 247, 247);
  height: 240px;
  /* width: 320px; */
}

.text-select-elements-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 160px;
}

.flex-col-space-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upload-btn-wrapper {
  text-align: end;
  margin-top: 50px;
}

.upload-btn {
  width: 96px;
}

.display-flex-space-btw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.form-section {
  display: flex;
  flex-direction: row;
  margin: 0 16px;
}

.close-icon-btn {
  width: 48px;
  height: 32px;
}

.close-icon-btn-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

/* .left-replace-button-wrapper {
  position: relative;
  float: left;
  bottom: 32px;
  left: 5px;
  background-color: white;
  border: none;
  width: 100px;
  height: 27.2px;
  align-items: center;
} */

.left-replace-button-wrapper {
  display: none;
}
.left-replace-button-wrapper:hover {
  background-color: white;
}

.right-replace-button-wrapper {
  position: relative;
  float: right;
  bottom: 32px;
  right: 5px;
  border: none;
  width: 100px;
  height: 27.2px;
  align-items: center;
  background-color: var(--quaternary-white-color);
}

.edit-button-wrapper {
  position: relative;
  float: right;
  bottom: 32px;
  right: 5px;
  border: none;
  width: 100px;
  height: 27.2px;
  align-items: center;
}

.right-replace-button-wrapper:hover {
  background-color: var(--quaternary-white-color);
  cursor: pointer;
}

.right-side-icon {
  background-color: white;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
}

.edit-delete-button-wrapper {
  position: relative;
  float: right;
  bottom: 32px;
  right: 15px;
  background-color: white;
  border: none;
  width: 80px;
  height: 27.2px;
  align-items: center;
}

.avator-edit {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
}

.edit-delete-button-wrapper:hover {
  background-color: white;
}

.cached-icon {
  margin-right: 8px;
}

.replace-text {
  margin-right: none;
}

.preview-image-section {
  height: 100%;
  /* width: 320px; */
  width: 100%;
  object-fit: cover;
}
.preview-image-section {
  /* object-fit: contain; */
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* border: none; */
}
.preview-video-section {
  height: 100%;
  /* width: 320px; */
  width: 100%;
  object-fit: cover;
}

.preview-video-section {
  object-fit: cover;
}

.image-upload-input {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.upload-icon {
  height: 30px;
  width: 30px;
}

.browse-files-link {
  color: #17171c;
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
}

.input-display-none {
  display: none;
}

.media-upload-content {
  margin-right: 48px;
  max-height: 320px;
}
