/* .quill {
  height: 12rem; 
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #0000003b;
}
.quill::-webkit-scrollbar {
  display: none;
}
.quill input:focus {
  border: 1px solid var(--secondary-theme-color);
}
.ql-toolbar {
  text-align: end;
  background-color: var(--tertiary-white-color);
  font-size: 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: none;
}
.ql-editor {
  font: 500 1.1rem "Jost-Medium";
  color: #000000;
}

.ql-list{
  margin-right : -23px ;
}
.ql-align{
  margin-right : -10px ;
} */

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
  min-height: 60px;
  /* overflow-y: scroll */
}

.ql-editor {
  font: 500 1.1rem "Jost-Medium";
  color: #000000;
}

.ql-editor-wrapper {
  border-radius: 4px;
  border: 1px solid #0000003b;
  padding-bottom: 42px;
}

.quill input:focus {
  border: 1px solid var(--secondary-theme-color);
}

/* Snow Theme */
.app .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-list {
  margin-right: -23px;
}
.ql-align {
  margin-right: -10px;
}

.ql-toolbar {
  /* text-align: end; */
  background-color: var(--tertiary-white-color);
  font-size: 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.app .ql-editor {
  min-height: 18em;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: none;
}

.ql-snow .ql-picker-label {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-left: 8px;
  padding-right: 16px;
  position: relative;
  width: 100%;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  width: 47px;
}
