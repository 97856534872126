.project-list-image{
    width : 140px;
    height : 80px;
    margin : -25px 0;
}

.info-icon {
    height : 20px;
    width : 20px;
}

.divider-section {
    margin : 0 -25px;
}

.save-btn-section {
    text-align : end;
    margin : 10px 0;
}

.remove-text{
    text-decoration : none
}

.sections-division{
    margin-top : 20px;
    margin-bottom : 20px
}

.stack-container{
    margin-top : 20px;
    margin-bottom : 20px;
}

.section-heading{
    padding-bottom : 10px
}

.submit-btn-section{
    margin : 20px 0
}

.heading-text-color {
    color : #494a67;
    font-size: 1.875rem;
    
    
}

.mapping-project-btn {
    margin-bottom : 10px
}

.heading-content-stack {
    margin-bottom : -24px
}


