.accordian-inner-fields-alignment{
    margin:-10px;
}

.divider-alignment{
    margin-top:20px;
    margin-bottom:20px;  
}

.heading-alignment{
    margin-bottom: 10px;
}