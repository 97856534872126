.content-section {
    padding : 20px 45px
}

.see-receipt-btn {
    font-size: 1.2rem;
    text-decoration: underline;
    /* color :  */
}

.payment-date {
    font-style: italic;
    color : #494a67;
    font-size: 1.4rem;
}

.payed-amount{
    font-weight: bold ;
}