.dialog-title-holder {
  padding: 40px 76px 10px 76px;
}

.project-image {
  width: 110px;
  height: 70px;
  object-fit: contain;
}

.search-input {
  width: 100%;
}

.selected-projects-count-text {
  font-size: 1rem;
  font-style: italic;
  font-weight: normal;
  color: var(--secondary-dark-gray-color);
  margin-left: 20px;
}

.dialog-table-pagination {
  padding-right: 76px;
  float: right;
}